<template>
    <v-container>
        <AError
        :api="this.api"/>
        <v-btn
        class="my-3"
        color="primary"
        @click="closeDetail">
            <v-icon>
                mdi-arrow-left-bold
            </v-icon>
        </v-btn>
        <v-card
        color="secondary">
            <div class="text-h5 pa-5 white--text">
                Payment status update 
            </div>
            <p class="px-5 white--text" v-if="this.company!=null">{{ company.name }}</p>
            <v-list 
            color="">
            <v-skeleton-loader
            ref="skeleton"
            type="card-avatar"
            v-if="api.isLoading">
            </v-skeleton-loader>
                <v-list-item
                v-for="(item,index) in payment"
                    :key="index">
                    <v-container>
                        <v-card>
                            <div class="pa-4">
                                <div v-if="item.payment_method" class="d-flex justify-start text-h6">
                                    {{item.payment_method}}
                                </div>
                                <div class="d-flex justify-start mb-2">
                                    <v-chip
                                    class="white--text"
                                    color="green"
                                    v-if="item.status=='Success'">
                                        {{ item.status }}
                                    </v-chip>
                                    <v-chip
                                    class="white--text"
                                    color="red"
                                    v-if="item.status=='Fail'">
                                        {{ item.status }}
                                    </v-chip>
                                    <v-chip
                                    class="black--text"
                                    color="yellow"
                                    v-if="item.status=='Pending'">
                                        {{ item.status }}
                                    </v-chip>
                                </div>
                                <div class="d-flex justify-start mb-2" v-if="item.total_amount!=null">
                                    RM {{ item.total_amount }} 
                                </div>

                                

                                <div class="d-flex justify-start mb-2" v-if="item.created_at!=null">
                                    {{ item.created_at.split("T")[0] }}
                                </div>
                                <div v-if="item.attachment_url!=null" class="mb-2">
                                    {{ item.attachment_type }} :
                                    <a :href="item.attachment_url" target="_blank" download>
                                         {{ item.attachment_name }}
                                    </a>
                                </div>
                                <!-- <div class="d-flex justify-start mb-2">
                                    {{ item.created_at.split("T")[0] }}
                                </div> -->
                                <!-- <div
                                v-if="item.renewal_status=='Confirm'">
                                    <div class="d-flex justify-start mb-2 font-weight-bold">
                                        Client promised pay date {{ item.confirm_at.split(" ")[0] }}
                                    </div>
                                </div> -->

                                    <!-- <div class="d-flex justify-start "
                                    v-if="item.coupon">
                                        <p
                                        class="text-decoration-line-through">
                                            RM {{ item.remark }} 
                                        </p>
                                        <div class="ml-2">
                                            ({{ item.coupon }})
                                        </div>
                                    </div> -->
                                <!-- <div class="d-flex justify-start">
                                    Total price  : 
                                    <p class="font-weight-bold">
                                        RM {{ item.amount_paid.substring(0,item.amount_paid.length-2) }}
                                    </p>
                                </div> -->

                                <hr>
                                <div class="d-flex justify-start my-2" v-if="item.fname!=null">
                                    Report by: 
                                    <div class="font-weight-bold">
                                        {{ item.fname }} {{ item.lname }}
                                    </div>
                                </div>
                                <div class="mt-2">
                                    <mavon-editor
                                    style="z-index:0;min-height:100px;height:auto;width:100%"
                                    height="auto"
                                    width="auto"
                                    defaultOpen="preview" 
                                    :toolbarsFlag="false" 
                                    :subfield="false"
                                    language="en"
                                    v-if="item.remark!=null"
                                    v-model="item.remark"
                                    :editable="false">
                                    </mavon-editor>
                                    <mavon-editor
                                    v-else
                                    style="z-index:0;min-height:100px;height:auto;width:100%"
                                    height="auto"
                                    width="auto"
                                    defaultOpen="preview" 
                                    :toolbarsFlag="false" 
                                    :subfield="false"
                                    language="en"
                                    v-model="isRemarkNull"
                                    :editable="false">
                                    </mavon-editor>
                                </div>
                                <!-- <p class="d-flex justify-start font-weight-bold">
                                    {{ item.status }}
                                    ( {{ item.created_at.split('T')[0] }} - {{ item.renewal_at.split(' ')[0] }})
                                </p> -->
                                <!-- <p class="d-flex justify-start font-weight-bold">
                                    ( {{ item.created_at }} - {{ item.ended_at }})
                                </p> -->
                            </div>
                        </v-card>
                    </v-container>
                </v-list-item>
            </v-list>
        </v-card>
    </v-container>
</template>

<script>
import mavonEditor from 'mavon-editor';
export default{
    components:{
        'mavon-editor':mavonEditor.mavonEditor,
    },
    data: () => ({
        company:null,
        payment:null,
        isRemarkNull:'No remark recorded',
       
        api:{
            isLoading:false,
            isError:false,
            isSuccesful:false,
            error:null,
            url:null,
        }
        }),
        created()
        {
            this.api.callbackReset = () => 
        {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp)=>
        {
            if(resp.class ==="readForm"){
                this.payment = resp.data.form;
                this.company = resp.data.company;
            }
            this.api.isLoading = false;
        }
        },
        mounted(){
            this.fetch();
        },
        methods:{
            closeDetail(){
                this.$router.go(-1);
            },
            fetch()
            {
                this.fetchForm();
            },
            fetchForm()
            {
                let companyId = this.$router.history.current.params.id;
                this.api.method = "get";
                this.api.url = process.env.VUE_APP_SERVER_API+"/form/"+companyId+"?formType=Payment";
                this.$api.fetch(this.api);
            }
        }
}
</script>